(function ($) {
  Drupal.behaviors.productInfo = {
    attach: function () {
      var sppSizeButton = $('.js-size-btn');
      var productWeight = $('.js-product-weight-list');
      var productWeightCointainer = $('.product-weight-spp');

      if (productWeightCointainer.length > 0) {
        productWeightCointainer.addClass('active');
      }
      if (sppSizeButton.length > 0) {
        productWeight.removeClass('selectBox');
        $('.desktop-block').find('.js-size-btn').first().addClass('active');
        $('.mobile-block').find('.js-size-btn').first().addClass('active');
        sppSizeButton.bind('click', function (event) {
          sppSizeButton.removeClass('active');
          event.preventDefault();
          var sku_base_id = $(this).attr('data-sku-base-id');

          productWeight.val(sku_base_id).trigger('change');
          $(this).addClass('active');
        });
      }
    }
  };
})(jQuery);
